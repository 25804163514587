import React from 'react';
import { Typography, Row, Col, Steps, Radio, Button, Modal, Form, Input, Select, message } from 'antd';
import {ArrowRightOutlined, ArrowLeftOutlined} from '@ant-design/icons';
import { AnimatePresence, motion } from 'framer-motion';
import { creditCheck, pageVariants, optionStates } from '../../Constants';
import OrderSummaryInfo, { getTotalIncGST } from '../../components/OrderSummaryInfo';
import { PaypalIcon, MasterCardIcon, VisaIcon, AmexIcon } from '../../elements/CustomIcons/CustomIcons';
import CheckoutLayout from '../../layouts/CheckoutLayout';
import { RadioChangeEvent } from 'antd/lib/radio';
import { UserContext } from '../../contexts/UserContext';
import { AddressesMessage, ShoppingCartsMessage, FreightMethodBean, PaymentMethodBean, MethodCallback, CheckoutMessage, CheckCCOrderPaymentMessage, UpdatePayPalOrderPaymentMessage, UserAccess, ClientHelper } from '../../RESTAPI';
import { CartContext } from '../../contexts/CartContext';
import { ProductContext } from '../../contexts/ProductContext';
import _ from 'lodash';
import { restClient, logout } from '../../elements/PrivateRoute/PrivateRoute';
import { Link, useHistory, useParams } from 'react-router-dom';
import PaypalButton from '../../components/PaypalButton';

const { Step } = Steps;

const { Text } = Typography;

const MAXSTEP = 4;

const CREDIT_CHECK = creditCheck;

const CheckoutPage: React.FC = () => {
    const [current, setCurrent] = React.useState<number>(0);
    const [address, setAddress] = React.useState<string>('');
    const [freight, setFreight] = React.useState<string>('');
    const [freightPrice, setFreightPrice] = React.useState<number>(0);
    const [backOrder, setBackOrder] = React.useState<string>('');
    const [payment, setPayment] = React.useState<string>('');
    const [loading, setLoading] = React.useState<boolean>(false);
    const [confirmingCheckout, setConfirmingCheckout] = React.useState<boolean>(false);
    const [checkoutPaymentVisible, setCheckoutPaymentVisible] = React.useState<boolean>(false);
    const [iframeSrc, setIframeSrc] = React.useState<string>('');
    const [iframeKey, setIframeKey] = React.useState<number>(1);

    const [webOrderNo, setWebOrderNo] = React.useState<string>('');
    const [ccPaymentId, setCCPaymentId] = React.useState<string>('');
    const [payPalPayment, setPayPalPayment] = React.useState<string>('');
    const [paypalClientId, setPaypalClientId] = React.useState<string>('');

    const [adminFee, setAdminFee] = React.useState<number>(0);

    const [addressForm] = Form.useForm();
    const [savedNewAddressValues, setSavedNewAddressValues] = React.useState<any>();
    const [addressOptions, setAddressOptions] = React.useState<Array<any>>([]);
    const [freightOptions, setFreightOptions] = React.useState<Array<any>>([]);
    const [backOrderOptions, setBackOrderOptions] = React.useState<Array<any>>([]);
    const [paymentOptions, setPaymentOptions] = React.useState<Array<any>>([]);
    const [approveCartId, setApproveCartId] = React.useState<string>('');

    const {userState, setAddresses} = React.useContext(UserContext);
    const {cartState, setShoppingCart, loadApprovingCart, updateCartFromCheckoutMessage} = React.useContext(CartContext);
    const {productState} = React.useContext(ProductContext);

    const [isCashCustomer, setIsCashCustomer] = React.useState<boolean>(true);

    const [customerEmail, setCustomerEmail] = React.useState<string>('');
    const [customerRef, setCustomerRef] = React.useState<string>('');

    const [displayCheckoutButton, setDisplayCheckoutButton] =  React.useState<boolean>(false);

    const [orderBaseTotalExGST, setOrderBaseTotalExGST] = React.useState<number>(0);
    const [freightChargeExGST, setFreightChargeExGST] = React.useState<number>(0);
    const [adminFeeExGST, setAdminFeeExGST] = React.useState<number>(0);
    const [totalGST, setTotalGST] = React.useState<number>(0);
    const [totalIncGST, setTotalIncGST] = React.useState<number>(0);

    const {cartId} = useParams<{cartId: string}>();
    const history = useHistory();

    const handleSaveAddress = (values: any) => {
      setSavedNewAddressValues(values);
      if (values.addressName) {
        restClient.addAddress(values.addressName, values.company === undefined ? '' : values.company, values.name, values.address1, values.address2 === undefined ? '' : values.address2, values.suburb, values.city === undefined ? '' : values.city, values.state === undefined ? '' : values.state, values.postcode, AddAddressCallback, {setAddresses});
      }
      let checkoutCart = cartState.shoppingCart ? cartState.shoppingCart.currentShoppingCartId : '';
      if(approveCartId !== '') {
          checkoutCart = approveCartId;
      }        
      restClient.checkoutSetDeliveryAddress(checkoutCart, values.company === undefined ? '' : values.company, values.name, values.address1, values.address2 === undefined ? '' : values.address2, '', '', values.suburb, values.city === undefined ? '' : values.city, values.state === undefined ? '' : values.state, values.postcode, CheckoutDeliveryAddressCallback);
    }

    const handleSaveAddressFail = () => {
      setLoading(false);
    }

    const AddAddressCallback: MethodCallback<AddressesMessage> = {
      onFailure(error: string): void {
        alert(error);
      },
      onProgress(loaded: number, total: number): void {},
      onSuccess(returnMessage: AddressesMessage, context?: any): void {
          if(!returnMessage.authenticated) {
              logout();
          } else {
  
              message.loading({ content: 'Adding address...', key: 'updatable' });
  
              if(returnMessage.error) {
                  setTimeout(() => {
                      message.error({ content: returnMessage.error, key: 'updatable', duration: 4 });
                  }, 200);
              } else {
                  setTimeout(() => {
                      message.success({ content: 'Saved address!', key: 'updatable', duration: 2 });
                      context.setAddresses(returnMessage.addresses);
                  }, 200);
              }
          }
      }
    }    

    const handleAddressSelect = (e: RadioChangeEvent) => {
        setAddress(e.target.value);
    }

    const handleFreightSelect = (e: RadioChangeEvent) => {
        setFreight(e.target.value);

        // find freight option
        const selectedFreight = _.find(freightOptions, (s) => {
            return s.value === e.target.value
        })

        setFreightPrice(selectedFreight?.charge);
    }

    const handleBackOrderSelect = (e: RadioChangeEvent) => {
        setBackOrder(e.target.value);

        // // find backOrder option
        // const selectedBackOrder = _.find(backOrderOptions, (s) => {
        //     return s.value === e.target.value
        // })
    }    

    const handlePaymentSelect = (e: RadioChangeEvent) => {
        if(e.target.value) {
            setPayment(e.target.value);
            let checkoutCart = cartState.shoppingCart ? cartState.shoppingCart.currentShoppingCartId : '';
            if(approveCartId !== '') {
                checkoutCart = approveCartId;
            }
            restClient.checkoutSetPaymentMethod(checkoutCart, e.target.value, true, CalculatePriceCheckoutPaymentCallback);             
        }
    }

    React.useEffect(() => {
        if(userState.currentCustomer && userState.addresses && userState.deliveryAddresses) {
            const listAddress = [] as Array<any>;

            if (userState.deliveryAddresses.addressIdToDeliveryAddress && userState.deliveryAddresses.addressIdToDeliveryAddress[userState.currentCustomer.deliveryId]) {
                const defaultAddress = userState.deliveryAddresses.addressIdToDeliveryAddress[userState.currentCustomer.deliveryId];
                listAddress.push({
                    label: (<div>
                        <p className='noMarginBottom text-grayColor'>{defaultAddress.addressName ? defaultAddress.addressName : 'Default Address'}</p>
                        <p className='noMarginBottom'><strong>{defaultAddress.contactName}</strong></p>
                        <p>{defaultAddress.lines[0]}<br/>{defaultAddress.lines[1].length > 0 ? <>{defaultAddress.lines[1]}<br/></> : null}{defaultAddress.lines[2].length > 0 ? <>{defaultAddress.lines[2]}<br/></> : null}{defaultAddress.lines[3].length > 0 ? <>{defaultAddress.lines[3]}<br/></> : null}{defaultAddress.suburb} {userState.currentCustomer.country.includes('NZ') ? defaultAddress.city : defaultAddress.state} {defaultAddress.postcode}</p>
                        </div>),
                    value: 'DEFAULTDELIVERYADDRESSFROMM3'
                })                
            }
            else if(userState.account && userState.account.invoiceAddress) {
                const defaultAddress = userState.account.invoiceAddress;                    
                listAddress.push({
                    label: (<div>
                        <p className='noMarginBottom text-grayColor'>{defaultAddress.addressName ? defaultAddress.addressName : 'Default Address'}</p>
                        <p className='noMarginBottom'><strong>{defaultAddress.contactName}</strong></p>
                        <p>{defaultAddress.lines[0]}<br/>{defaultAddress.lines[1].length > 0 ? <>{defaultAddress.lines[1]}<br/></> : null}{defaultAddress.lines[2].length > 0 ? <>{defaultAddress.lines[2]}<br/></> : null}{defaultAddress.lines[3].length > 0 ? <>{defaultAddress.lines[3]}<br/></> : null}{defaultAddress.suburb} {userState.currentCustomer.country.includes('NZ') ? defaultAddress.city : defaultAddress.state} {defaultAddress.postcode}</p>
                        </div>),
                    value: 'DEFAULTINVOICEADDRESSFROMM3'
                })
            }

            if (userState.userProfile.type === 'S' || userState.currentCustomer.country.includes('NZ') || ClientHelper.isCustomAddressesAllowed(userState.currentCustomer)) {

                userState.addresses.forEach(a => {
                    if(a.addressId) {
                        listAddress.push({
                            label: (<div>
                                <p className='noMarginBottom text-grayColor'>{a.addressName}</p>
                                <p className='noMarginBottom'><strong>{a.contactName}</strong></p>
                                <p>{a.lines[0]}<br/>{a.lines[1].length > 0 ? <>{a.lines[1]}<br/></> : null}{a.lines[2].length > 0 ? <>{a.lines[2]}<br/></> : null}{a.lines[3].length > 0 ? <>{a.lines[3]}<br/></> : null}{a.suburb} {userState.currentCustomer.country.includes('NZ') ? a.city : a.state} {a.postcode}</p>
                                </div>),
                            value: a.addressId
                        })
                    }
                });

                listAddress.push({
                    label: (<div>
                        <p className='noMarginBottom text-grayColor'>New Address</p>
                        </div>),
                    value: 'NEW'   
                  })             
      
            }

            setAddressOptions(listAddress);
            if (address.length === 0) {
                setAddress(listAddress[0] ? listAddress[0].value : '');
            }
        }

        if(productState.productIdToProduct && cartState.cartItems && userState) {

            const listBackOrder = [] as Array<any>;
            listBackOrder.push({
                label: 'Don\'t ship any items within the order until the whole order can be shipped at once (no splitting of the order permitted)',
                value: 'false'
            })            
            listBackOrder.push({
                label: 'Ship all in stock products now and ship others as available',
                value: 'true'
            })            
            setBackOrderOptions(listBackOrder);
            if (backOrder.length === 0) {
                setBackOrder('true');
            }

            // NZ only use DIRECT and PICKUP and AU only uses CREDIT, DIRECT and PAYPAL
            if(userState.userProfile.businessGroup) {
                const listPayment = [] as Array<any>;
                
                if(userState.currentCustomer.country.includes('NZ')) {
                    listPayment.push({
                        label: (<div>
                            <p className='noMarginBottom'><strong>{PaymentMethodBean.DIRECT_DEPOSIT.getDescription()}</strong></p>
                            </div>), value: PaymentMethodBean.DIRECT_DEPOSIT.getId()
                        });
                    
                    listPayment.push({
                        label: (<div>
                            <p className='noMarginBottom'><strong>{PaymentMethodBean.CUSTOMER_PICKUP.getDescription()}</strong></p>
                            </div>), value: PaymentMethodBean.CUSTOMER_PICKUP.getId()
                        });
                } else {
                    listPayment.push({
                        label: (<div>
                            <p className='noMarginBottom'><strong>{PaymentMethodBean.DIRECT_DEPOSIT.getDescription()}</strong></p>
                            </div>), value: PaymentMethodBean.DIRECT_DEPOSIT.getId()
                        });
                    listPayment.push({
                        label: (<div>
                            <p className='noMarginBottom'><strong>{PaymentMethodBean.VISA_CREDIT.getDescription()} ({ClientHelper.decimalToPercentage(PaymentMethodBean.VISA_CREDIT.getSurchargePct())} processing fee)</strong></p>
                            {/*<CreditCardForm/>*/}
                            </div>), value: PaymentMethodBean.VISA_CREDIT.getId()
                        });
                    listPayment.push({
                        label: (<div>
                            <p className='noMarginBottom'><strong>{PaymentMethodBean.VISA_DEBIT.getDescription()} ({ClientHelper.decimalToPercentage(PaymentMethodBean.VISA_DEBIT.getSurchargePct())} processing fee)</strong></p>
                            {/*<CreditCardForm/>*/}
                            </div>), value: PaymentMethodBean.VISA_DEBIT.getId()
                        });
                    listPayment.push({
                        label: (<div>
                            <p className='noMarginBottom'><strong>{PaymentMethodBean.MASTERCARD_CREDIT.getDescription()} ({ClientHelper.decimalToPercentage(PaymentMethodBean.MASTERCARD_CREDIT.getSurchargePct())} processing fee)</strong></p>
                            {/*<CreditCardForm/>*/}
                            </div>), value: PaymentMethodBean.MASTERCARD_CREDIT.getId()
                        });
                    listPayment.push({
                        label: (<div>
                            <p className='noMarginBottom'><strong>{PaymentMethodBean.MASTERCARD_DEBIT.getDescription()} ({ClientHelper.decimalToPercentage(PaymentMethodBean.MASTERCARD_DEBIT.getSurchargePct())} processing fee)</strong></p>
                            {/*<CreditCardForm/>*/}
                            </div>), value: PaymentMethodBean.MASTERCARD_DEBIT.getId()
                        });
                    listPayment.push({
                        label: (<div>
                            <p className='noMarginBottom'><strong>{PaymentMethodBean.AMEX.getDescription()} ({ClientHelper.decimalToPercentage(PaymentMethodBean.AMEX.getSurchargePct())} processing fee)</strong></p>
                            {/*<CreditCardForm/>*/}
                            </div>), value: PaymentMethodBean.AMEX.getId()
                        });
                    listPayment.push({
                        label: (<div>
                            <p className='noMarginBottom'><strong>{PaymentMethodBean.PAYPAL.getDescription()} ({ClientHelper.decimalToPercentage(PaymentMethodBean.PAYPAL.getSurchargePct())} processing fee)</strong></p>
                            </div>), value: PaymentMethodBean.PAYPAL.getId()
                        });
                }

                setPaymentOptions(listPayment);
                if (payment.length === 0) {
                    setPayment(listPayment[0].value);
                }
            }

        }
    }, [userState, userState.addresses, userState.account, cartState, productState])

    React.useEffect(() => {
        const paymentObj = PaymentMethodBean.get(payment);
        if(paymentObj) {
            setAdminFee(paymentObj.getSurchargePct());
        }
    }, [payment])

    React.useEffect(() => {
        if (userState && userState.currentCustomer && userState.currentPayer) {
            setIsCashCustomer(ClientHelper.isCashCustomer(userState.currentCustomer, userState.currentPayer));
            setCustomerEmail(userState.userProfile?.email);
            setCustomerRef("");
        }
    }, [userState.currentCustomer, userState.userProfile, userState.currentUserAccount, userState.currentPayer])

    React.useEffect(() => {
        if(cartState && cartState.shoppingCart) {
            if(cartId) {
                // check if cartId is in approving cart
                if(cartState.shoppingCart.shoppingCartIdToShoppingCart[cartId] && cartState.shoppingCart.shoppingCartIdToShoppingCart[cartId].status === 'WA' && cartState.shoppingCart.shoppingCartIdToShoppingCart[cartId].type === 'T') {
                    if(userState.currentUserAccount.permissions.includes(UserAccess.APPROVE_ORDERS)) {
                        loadApprovingCart(cartId);
                        setApproveCartId(cartId);

                        restClient.checkoutApprovalShoppingCart(cartId, CheckoutCurrentCartCallback)
                    }
                }
            } else {
                restClient.checkoutCurrentShoppingCart(CheckoutCurrentCartCallback);
            }
        }
    }, [cartId, cartState.shoppingCart])

    const handleCustomerEmail = (e: React.ChangeEvent<HTMLInputElement>) => {
        setCustomerEmail(e.currentTarget.value);
    }

    const handleCustomerRef = (e: React.ChangeEvent<HTMLInputElement>) => {
        setCustomerRef(e.currentTarget.value);
    }

    const step = (key: number) => {
        let result:React.ReactNode = '';
        switch (key) {
            case 0:
                result = (<motion.div key={key} initial='initial' animate="in" exit="out" variants={pageVariants}>
                    <div className='checkoutStep'>
                        <Input disabled={loading} size="large" value={customerEmail} onChange={handleCustomerEmail} className="has-float-label marginBottom15px" placeholder='Confirmation Email' suffix={<label className="floating-label" htmlFor="name">Customer Email</label>}/>  
                        <Input disabled={loading} size="large" value={customerRef} onChange={handleCustomerRef} className="has-float-label" placeholder='Customer Ref' suffix={<label className="floating-label" htmlFor="name">Customer Ref</label>}/>  
                    </div>
                </motion.div>)
                break;

            case 1:
                result = (<motion.div key={key} initial='initial' animate="in" exit="out" variants={pageVariants}>
                    <div className='checkoutStep'>
                        <Radio.Group
                            disabled={loading}
                            options={addressOptions}
                            value={address}
                            className='verticalRadioGroup'
                            onChange={handleAddressSelect}
                        />
                        <div className={address === 'NEW' ? 'displayBlock' : 'displayNone'} style={{ marginRight: '10px'}}>
                          <Form form={addressForm} layout="vertical" hideRequiredMark onFinish={(values) => {handleSaveAddress(values)}} onFinishFailed={handleSaveAddressFail}>
                            <Row gutter={[16, 16]}>
                              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                                  <Text>*Specify an Address Name to save this address for future use (optional)</Text>
                              </Col>                               
                              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                                <Form.Item hasFeedback name='addressName' label='' rules={[{required: false, message: 'Please input your address nameif you want to save for future use!' }]}> 
                                    <Input size="large" className="has-float-label" placeholder='Address Name' suffix={<label className="floating-label" htmlFor="addressName">Address Name</label>}/> 
                                </Form.Item>
                              </Col>
                            </Row>
                            <Row gutter={[16, 16]}>
                              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                                <Form.Item hasFeedback name='name' label='' rules={[{required: true, message: 'Please input your name!' }]}> 
                                    <Input size="large" className="has-float-label" placeholder='Name' suffix={<label className="floating-label" htmlFor="name">Name</label>}/> 
                                </Form.Item>
                              </Col>
                              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                                <Form.Item hasFeedback name='company' label='' rules={[{required: false, message: 'Please input your company!' }]}> 
                                    <Input size="large" className="has-float-label" placeholder='Company' suffix={<label className="floating-label" htmlFor="company">Company</label>}/> 
                                </Form.Item>
                              </Col>
                            </Row>
                            <Row gutter={[16, 16]}>
                              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                                <Form.Item hasFeedback name='address1' label='' rules={[{required: true, message: 'Please input your address!' }]}> 
                                    <Input size="large" className="has-float-label" placeholder='Address Line 1' suffix={<label className="floating-label" htmlFor="address1">Address Line 1</label>}/> 
                                </Form.Item>
                              </Col>
                              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                              <Form.Item hasFeedback name='address2' label='' rules={[{required: false, message: 'Please input your address!' }]}> 
                                    <Input size="large" className="has-float-label" placeholder='Address Line 2' suffix={<label className="floating-label" htmlFor="address2">Address Line 2</label>}/> 
                                </Form.Item>
                              </Col>
                            </Row>
                            <Row gutter={[16, 16]}>
                              <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                                <Form.Item hasFeedback name='postcode' label='' rules={[{required: true, message: 'Please input your postcode!' }]}> 
                                    <Input size="large" className="has-float-label" type='number' placeholder='Postcode' suffix={<label className="floating-label" htmlFor="postcode">Postcode</label>}/> 
                                </Form.Item>
                              </Col>
                              <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                                <Form.Item hasFeedback name='suburb' label='' rules={[{required: true, message: 'Please input your suburb!' }]}> 
                                    <Input size="large" className="has-float-label" placeholder='Suburb' suffix={<label className="floating-label" htmlFor="suburb">Suburb</label>}/> 
                                </Form.Item>
                              </Col>
                              {
                                userState.currentCustomer.country.includes('NZ') ?
                                (   
                                  <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                                    <Form.Item hasFeedback name='city' label='' rules={[{required: true, message: 'Please input your city!' }]}> 
                                        <Input size="large" className="has-float-label" placeholder='City' suffix={<label className="floating-label" htmlFor="city">City</label>}/> 
                                    </Form.Item>
                                  </Col>                             
                                )
                                :
                                (
                                  <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                                    <Form.Item hasFeedback name='state' label='' rules={[{required: true, message: 'Please input your state!' }]}> 
                                        <Select size="large" className="has-float-label add-address-state" placeholder='State' options={optionStates}/> 
                                    </Form.Item>
                                  </Col>
                                )
                              }
                            </Row>
                          </Form>
                        </div>
                        <br/>
                        <br/>
                        <br/>
                        <br/>
                    </div>
                </motion.div>)
                break;                

            case 2:
                result = (<motion.div key={key} initial='initial' animate="in" exit="out" variants={pageVariants}>
                    <div className='checkoutStep'>
                        <h5>Freight Options</h5>
                        <Radio.Group
                            options={freightOptions}
                            value={freight}
                            className='verticalRadioGroup'
                            onChange={handleFreightSelect}
                        />
                        <h5>Products on Backorder</h5>
                        <Radio.Group
                            options={backOrderOptions}
                            value={backOrder}
                            className='verticalRadioGroup'
                            onChange={handleBackOrderSelect}
                        />
                    </div>
                </motion.div>)
                break;

            case 3:
                result = (<motion.div key={key} initial='initial' animate="in" exit="out" variants={pageVariants}>
                    <div className='checkoutStep'>
                        <Radio.Group
                            options={paymentOptions}
                            value={payment}
                            className='verticalRadioGroup'
                            onChange={handlePaymentSelect}
                        />
                    </div>
                </motion.div>)
                break;
            
            case 4:
                let descAddress = _.find(addressOptions, (a: any) => {
                    return a.value === address
                })

                let descFreight = _.find(freightOptions, (a: any) => {
                    return a.value === freight
                })
                
                let descBackOrder = _.find(backOrderOptions, (a: any) => {
                    return a.value === backOrder
                })                

                result = (<motion.div key={key} initial='initial' animate="in" exit="out" variants={pageVariants}>
                    <div className='checkoutStep'>
                        <div className='flexSpaceBetween cartDetail'>
                            <strong>Customer Email</strong>
                            <p>{customerEmail}</p>
                        </div>

                        <div className='flexSpaceBetween cartDetail'>
                            <strong>Customer Reference</strong>
                            <p>{customerRef}</p>
                        </div>

                        <div className='flexSpaceBetween cartDetail'>
                            <strong>Shipping Address</strong>
                            {descAddress.value === 'NEW' ? (<div>
                            <p className='noMarginBottom text-grayColor'>{savedNewAddressValues.addressName === undefined ? 'New Address' : savedNewAddressValues.addressName}</p>
                            <p className='noMarginBottom'><strong>{savedNewAddressValues.name}</strong></p>
                            <p>{savedNewAddressValues.address1}<br/>{savedNewAddressValues.address2 === undefined ? null : savedNewAddressValues.address2}{savedNewAddressValues.address2 === undefined ? null : <br/>} {savedNewAddressValues.suburb} {userState.currentCustomer.country.includes('NZ') ? savedNewAddressValues.city : savedNewAddressValues.state} {savedNewAddressValues.postcode}</p>
                            </div>) : descAddress.label}
                        </div>

                        <div className='flexSpaceBetween cartDetail'>
                            <strong>Freight Option</strong>
                            <p>{descFreight.label}</p>
                        </div>  

                        <div className='flexSpaceBetween cartDetail'>
                            <strong>Products on Backorder</strong>
                            <div className='backOrderLabel'>{descBackOrder.label}</div>
                        </div>
                        { isCashCustomer ? 
                        <div className='flexSpaceBetween cartDetail'>
                            <strong>Payment Type</strong>
                            <p>{PaymentMethodBean.get(payment) ? PaymentMethodBean.get(payment)?.getDescription() : ''}</p>
                        </div> : null }
                    </div>
                </motion.div>)
                break;

            default:
                break;
        }

        return result;
    }

    const updateValidFreightMethods = (checkoutMessage: CheckoutMessage) => {
      if (checkoutMessage && checkoutMessage.validFreightMethods) {
        const freightMethods = checkoutMessage.validFreightMethods;
        const listFreight = [] as Array<any>;

        if(freightMethods) {
            freightMethods.forEach(s => {
                listFreight.push({
                    label: s.name,
                    value: s.id,
                    charge: s.chargeExGST,
                    code: s.code
                })
            });
        }
        
        setFreightOptions(listFreight);
        if (freight.length === 0) {
            setFreight(listFreight[0].value);
            setFreightPrice(listFreight[0].charge);
        }
      }
    }

    // checkout current cart
    const CheckoutCurrentCartCallback: MethodCallback<CheckoutMessage> = {
        onFailure(error: string, context: any): void {
            alert("Error talking to server - please check network connection");
            setLoading(false);
        },
    
        onProgress(loaded: number, total: number): void {},
    
        onSuccess(message: CheckoutMessage, context: any): void {
            if(message.authenticated) {
                if(message.error === null || message.error === '') {
                    setWebOrderNo(message.webOrderNo);
                    setOrderBaseTotalExGST(message.orderBaseTotalExGST);
                    setFreightChargeExGST(message.freightChargeExGST);
                    setAdminFeeExGST(message.adminFeeExGST);
                    setTotalGST(message.totalGST);
                    setTotalIncGST(message.totalIncGST);
                    updateCartFromCheckoutMessage(message);
                    updateValidFreightMethods(message);
                    if (message.warnings !== null && message.warnings.length > 0) {
                        for (const warning of message.warnings) {
                            alert(warning);
                        }                        
                    }
                }
                else {
                    alert(message.error);
                }
                setLoading(false);
            } else {
                logout()
            }
        }
    }

    // 1st step
    const CheckoutConfirmEmailCustRefCallback: MethodCallback<CheckoutMessage> = {
        onFailure(error: string, context: any): void {
            alert("Error talking to server - please check network connection");
            setLoading(false);
        },
    
        onProgress(loaded: number, total: number): void {},
    
        onSuccess(message: CheckoutMessage, context: any): void {
            if(message.authenticated) {
                if(message.error === null || message.error === '') {
                    setCurrent(current + 1);                    
                    setOrderBaseTotalExGST(message.orderBaseTotalExGST);
                    setFreightChargeExGST(message.freightChargeExGST);
                    setAdminFeeExGST(message.adminFeeExGST);
                    setTotalGST(message.totalGST);
                    setTotalIncGST(message.totalIncGST);
                    updateCartFromCheckoutMessage(message);
                    updateValidFreightMethods(message);
                    if (message.warnings !== null && message.warnings.length > 0) {
                        for (const warning of message.warnings) {
                            alert(warning);
                        }                        
                    }                    
                } else {
                    alert(message.error);
                }
                setLoading(false);                
            } else {
                logout()
            }
        }
    }

    // 2nd step
    const CheckoutDeliveryAddressCallback: MethodCallback<CheckoutMessage> = {
        onFailure(error: string, context: any): void {
            alert("Error talking to server - please check network connection");
            setLoading(false);
        },
    
        onProgress(loaded: number, total: number): void {},
    
        onSuccess(message: CheckoutMessage, context: any): void {
            if(message.authenticated) {
                if(message.error === null || message.error === '') {
                    setCurrent(current + 1);
                    setOrderBaseTotalExGST(message.orderBaseTotalExGST);
                    setFreightChargeExGST(message.freightChargeExGST);
                    setAdminFeeExGST(message.adminFeeExGST);
                    setTotalGST(message.totalGST);
                    setTotalIncGST(message.totalIncGST);
                    updateCartFromCheckoutMessage(message);
                    updateValidFreightMethods(message);
                    if (message.warnings !== null && message.warnings.length > 0) {
                        for (const warning of message.warnings) {
                            alert(warning);
                        }                        
                    }                                        
                } else {
                    alert(message.error);
                }
            } else {
                logout()
            }
            setLoading(false);
        }
    }

    // 3rd step
    const CheckoutFreightBackOrderOptionsCallback: MethodCallback<CheckoutMessage> = {
        onFailure(error: string, context: any): void {
            alert("Error talking to server - please check network connection");
            setLoading(false);
        },
    
        onProgress(loaded: number, total: number): void {},
    
        onSuccess(message: CheckoutMessage, context: any): void {
            if(message.authenticated) {
                if(message.error === null || message.error === '') {
                    setOrderBaseTotalExGST(message.orderBaseTotalExGST);
                    setFreightChargeExGST(message.freightChargeExGST);
                    setAdminFeeExGST(message.adminFeeExGST);
                    setTotalGST(message.totalGST);
                    setTotalIncGST(message.totalIncGST);                    
                    if (!isCashCustomer) {
                        setCurrent(current + 2);
                        setDisplayCheckoutButton(true);
                    } else {
                        setCurrent(current + 1);
                    }
                    updateCartFromCheckoutMessage(message);
                    updateValidFreightMethods(message);
                    if (message.warnings !== null && message.warnings.length > 0) {
                        for (const warning of message.warnings) {
                            alert(warning);
                        }                        
                    }                    
                } else {
                    if (!isCashCustomer) {
                        setDisplayCheckoutButton(false);
                    }
                    alert(message.error);
                }
            } else {
                logout()
            }
            setLoading(false);
        }
    }
    
    // 4rd step
    const CalculatePriceCheckoutPaymentCallback: MethodCallback<CheckoutMessage> = {
        onFailure(error: string, context: any): void {
            alert("Error talking to server - please check network connection");
        },
    
        onProgress(loaded: number, total: number): void {},
    
        onSuccess(message: CheckoutMessage, context: any): void {
            if(message.authenticated) {
                if(message.error === null || message.error === '') {
                    setOrderBaseTotalExGST(message.orderBaseTotalExGST);
                    setFreightChargeExGST(message.freightChargeExGST);
                    setAdminFeeExGST(message.adminFeeExGST);
                    setTotalGST(message.totalGST);
                    setTotalIncGST(message.totalIncGST);
                    updateCartFromCheckoutMessage(message);
                    updateValidFreightMethods(message);
                    if (message.warnings !== null && message.warnings.length > 0) {
                        for (const warning of message.warnings) {
                            alert(warning);
                        }                        
                    }                                        
                } else {
                    alert(message.error);
                }
            } else {
                logout()
            }
        }
    }

    const CheckoutPaymentCallback: MethodCallback<CheckoutMessage> = {
      onFailure(error: string, context: any): void {
          alert("Error talking to server - please check network connection");
          setLoading(false);
      },
  
      onProgress(loaded: number, total: number): void {},
  
      onSuccess(message: CheckoutMessage, context: any): void {
          if(message.authenticated) {
              if(message.error === null || message.error === '') {
                  setOrderBaseTotalExGST(message.orderBaseTotalExGST);
                  setFreightChargeExGST(message.freightChargeExGST);
                  setAdminFeeExGST(message.adminFeeExGST);
                  setTotalGST(message.totalGST);
                  setTotalIncGST(message.totalIncGST);    
                  setPayPalPayment('');
                  setPaypalClientId('');             
                  setDisplayCheckoutButton(true);
                  setCurrent(current + 1);
                  updateCartFromCheckoutMessage(message);
                  updateValidFreightMethods(message);
                  if (message.warnings !== null && message.warnings.length > 0) {
                      for (const warning of message.warnings) {
                          alert(warning);
                      }                        
                  }                  
                  if (payment === PaymentMethodBean.PAYPAL.getId()) {
                    handleCheckout();
                  }
              } else {
                  setDisplayCheckoutButton(false);
                  alert(message.error);
              }
          } else {
              logout()
          }
          setLoading(false);
      }
  }    

    // 5th step
    const CheckoutCompleteCallback: MethodCallback<CheckoutMessage> = {
        
        onFailure(error: string, context: any): void {
            alert("Error talking to server - please check network connection");
            setLoading(false);
            setConfirmingCheckout(false);
        },
    
        onProgress(loaded: number, total: number): void {},
    
        onSuccess(message: CheckoutMessage, context: any): void {
            if(message.authenticated) {
                if(message.error === null || message.error === '') {
                    // its done, waiting for checkout
                    setDisplayCheckoutButton(false);
                    if(isCashCustomer && (payment === PaymentMethodBean.PAYPAL.getId() || payment === PaymentMethodBean.MASTERCARD_DEBIT.getId() || payment === PaymentMethodBean.MASTERCARD_CREDIT.getId() || payment === PaymentMethodBean.VISA_CREDIT.getId() || payment === PaymentMethodBean.VISA_DEBIT.getId() || payment === PaymentMethodBean.AMEX.getId())) {
                        // waiting for successful
                        if (message.ccPaymentId != null) {
                            // setccPaymentId
                            setCCPaymentId(message.ccPaymentId);
                            setWebOrderNo(message.webOrderNo);
                            
                            // open iframe
                            setIframeSrc(CREDIT_CHECK + '?webOrderNo=' + message.webOrderNo);
                            setIframeKey(iframeKey + 1); // this forces and iframe update even if the src is the same
                            setCheckoutPaymentVisible(true);
                        }

                        if (message.payPalPayment != null) {
                            // setccPaymentId
                            setPayPalPayment(message.payPalPayment);
                            setPaypalClientId(message.payPalClientId);
                            setWebOrderNo(message.webOrderNo);
                        }
                    } else {
                        if (userState.orders && userState.orders.orders && userState.orders.orderNoToOrderLines && message.order && message.orderLines) {
                            userState.orders.orders.push(message.order);
                            userState.orders.orderNoToOrderLines[message.order.orderno] = message.orderLines;
                        }
                        restClient.shoppingCartsFromServer(ShoppingCartCallback);
                        let orderNo = message.movexOrderNo.length > 0 ? message.movexOrderNo : message.movexTempOrderNo;
                        history.push('/order-success/' + orderNo);
                    }
                } else {
                    alert(message.error);
                }
            } else {
                logout();
            }
            setLoading(false);
            setConfirmingCheckout(false);
        }
    }

    // check if payment got through via Paypal
    const updatePayPalOrderPaymentCallback: MethodCallback<UpdatePayPalOrderPaymentMessage> = {
        onFailure(error: string, context: any): void {
            alert("Error talking to server - please check network connection");
        },
    
        onProgress(loaded: number, total: number): void {},
    
        onSuccess(message: UpdatePayPalOrderPaymentMessage, context: any): void {
            if(message.authenticated) {
                if(message.error === null || message.error === '') {
                    // success
                    if(message.status === 'Ordered') {
                        if (userState.orders && userState.orders.orders && userState.orders.orderNoToOrderLines && message.order && message.orderLines) {
                            userState.orders.orders.push(message.order);
                            userState.orders.orderNoToOrderLines[message.order.orderno] = message.orderLines;
                        }
                        restClient.shoppingCartsFromServer(ShoppingCartCallback);
                        let orderNo = message.movexOrderNo.length > 0 ? message.movexOrderNo : message.movexTempOrderNo;
                        history.push('/order-success/' + orderNo);
                    } else {
                        alert("Unexpected status after PayPal payment - please contact cellnet support");
                    }
                } else {
                    alert(message.error);
                }
            } else {
                logout();
            }
            setLoading(false);
        }
    }

    // check if payment got throuhg
    const checkCCOrderPaymentStatusCallback: MethodCallback<CheckCCOrderPaymentMessage> = {
        onFailure(error: string, context: any): void {
            alert("Error talking to server - please check network connection");
        },
    
        onProgress(loaded: number, total: number): void {},
    
        onSuccess(message: CheckCCOrderPaymentMessage, context: any): void {
            if(message.authenticated) {
                if(message.error === null || message.error === '') {
                    if(message.status === 'ORDERED') {
                        if (userState.orders && userState.orders.orders && userState.orders.orderNoToOrderLines && message.order && message.orderLines) {
                            userState.orders.orders.push(message.order);
                            userState.orders.orderNoToOrderLines[message.order.orderno] = message.orderLines;
                        }
                        restClient.shoppingCartsFromServer(ShoppingCartCallback);
                        let orderNo = message.movexOrderNo.length > 0 ? message.movexOrderNo : message.movexTempOrderNo;
                        history.push('/order-success/' + orderNo);
                    }
                    else if(message.status === 'COMPLETE') {
                        // hmmm TODO
                        alert('Complete');
                    } else {
                        // do it again as the user has not submitted any credit card or payment details TODO
                    }
                } else {
                    alert(message.error);
                }
            } else {
                logout()
            }
            setLoading(false);
        }
    }

    const ShoppingCartCallback: MethodCallback<ShoppingCartsMessage> = {
      onFailure(error: string, context: any): void {
      },
    
      onProgress(loaded: number, total: number): void {},
    
      onSuccess(message: ShoppingCartsMessage, context: any): void {
          if(message.authenticated) {
              setShoppingCart(message);
          } else {
              logout();
          }
      }
    }      

    const handleCheckout = () => {
      if (!confirmingCheckout) {      
        setConfirmingCheckout(true);
        setLoading(true);
        let checkoutCart = cartState.shoppingCart ? cartState.shoppingCart.currentShoppingCartId : '';
        if(approveCartId !== '') {
            checkoutCart = approveCartId;
        }

        restClient.checkoutConfirm(checkoutCart, CheckoutCompleteCallback);

        /*if(approveCartId !== '') {
            restClient.checkoutApprovalShoppingCart(approveCartId, CheckoutCurrentCartCallback);
        } else {
            restClient.checkoutCurrentShoppingCart(CheckoutCurrentCartCallback);
        }*/
      }
    }

    const stepTitle = () => {
        let result = '';
        
        switch (current) {
            case 0:
                result = 'Options';
                break;

            case 1:
                result = 'Delivery Address';
                break;

            case 2:
                result = 'Freight & Back Order';
                break;

            case 3:
                result = 'Payment';
                break;
        
            case 4:
                result = 'Order Details';
                break;

            default:
                break;
        }

        return result;
    }

    const prevStepTitle = () => {
        let result = '';

        switch (current) {
            case 0:
                result = 'Cart';
                break;
            
            case 1:
                result = 'Options';
                break;

            case 2:
                result = 'Address';
                break;

            case 3:
                result = 'Freight';
                break;

            case 4:
                result = isCashCustomer ? 'Payment' : 'Freight';
                break;
        
            default:
                break;
        }

        return result;
    }

    const handleGoBack = () => {
        const goBackStep = current - 1;

        if(goBackStep < 0) {
            history.push('/cart');
        } else if (isCashCustomer && current == 4) {
            setCurrent(3);
        } else {
            setCurrent(current - 1);
        }
        
    }

    const nextStepTitle = () => {
        let result = '';

        switch (current) {
            case 0:
                result = 'Address';
                break;

            case 1:
                result = 'Freight';
                break;

            case 2:
                result = isCashCustomer ? 'Payment' : 'Order Details';
                break;

            case 3:
                result = 'Order Details';
                break;
        
            default:
                break;
        }

        return result;
    }

    const iFrameChange = () => {
        if(ccPaymentId !== '' && webOrderNo !== '') {
            restClient.checkCCOrderPaymentStatus(webOrderNo, ccPaymentId, checkCCOrderPaymentStatusCallback);
        }
    }

    const handleNext = (step:number) => {
        let checkoutCart = cartState.shoppingCart ? cartState.shoppingCart.currentShoppingCartId : '';
        if(approveCartId !== '') {
            checkoutCart = approveCartId;
        }
        switch (step) {
            case 0:
                setLoading(true);
                restClient.checkoutSetConfirmEmailCustRef(checkoutCart, customerEmail, customerRef, CheckoutConfirmEmailCustRefCallback);
                break;

            case 1:
                setLoading(true);
                if (address === 'NEW') {
                    addressForm.submit();
                }
                else {
                    let selectedAddress = undefined;
                    if (address === 'DEFAULTDELIVERYADDRESSFROMM3') {
                        selectedAddress = userState.deliveryAddresses.addressIdToDeliveryAddress[userState.currentCustomer.deliveryId];
                    }
                    else if (address === 'DEFAULTINVOICEADDRESSFROMM3') {
                        selectedAddress = userState.account.invoiceAddress;
                    }
                    else {
                        selectedAddress = _.find(userState.addresses, (a) => {
                            return a.addressId === address;
                        })
                    }

                    if(selectedAddress) {
                        restClient.checkoutSetDeliveryAddress(checkoutCart, selectedAddress.companyName, selectedAddress.contactName, selectedAddress.lines[0], selectedAddress.lines[1], selectedAddress.lines[2], selectedAddress.lines[3], selectedAddress.suburb, selectedAddress.city, selectedAddress.state, selectedAddress.postcode, CheckoutDeliveryAddressCallback)
                    } else {
                        alert('Selected address is invalid!')
                        setLoading(false);
                    }                
                }
                break;                

            case 2:
                setLoading(true);
                restClient.checkoutSetFreightBackOrderOptions(checkoutCart, freight, 'true' === backOrder, CheckoutFreightBackOrderOptionsCallback);
                break;

            case 3:
                setLoading(true);
                if (isCashCustomer) {
                    restClient.checkoutSetPaymentMethod(checkoutCart, payment, false, CheckoutPaymentCallback);
                }
                break;
        
            default:
                setLoading(false);
                break;
        }
    }

    return (
        <CheckoutLayout>
            <Row className='whiteBg marginBottom40px'>
                <Col span={24}>
                    <div className='content-wrapper '>
                    <Steps
                        type="navigation"
                        size="small"
                        className="site-navigation-steps"
                        current={current}
                        onChange={(current) => setCurrent(current)}
                        >
                        <Step status={current >= 0 ? 'process': 'wait'} title="Options"/>
                        <Step status={current >= 1 ? 'process': 'wait'} title="Address" disabled={current < 1 ? true: false}/>
                        <Step status={current >= 2 ? 'process': 'wait'} title="Freight" disabled={current < 2 ? true: false}/>
                        {
                            isCashCustomer && <Step status={current >= 3 ? 'process': 'wait'} title="Payment" disabled={current < 3 ? true: false} />
                        }
                        <Step status={(isCashCustomer && current >= 4) || (!isCashCustomer && current >= 3) ? 'process': 'wait'} title="Confirm Order" disabled={(isCashCustomer && current < 4) || (!isCashCustomer && current < 3) ? true: false}/>
                    </Steps>
                    </div>
                </Col>
            </Row>
            <div className='content-wrapper marginBottom40px'> 
                <Row>
                    <Col span={24}>
                        <h2 className='headerTitle textCenter'>CHECKOUT</h2>
                    </Col>
                </Row>
                <Row gutter={30}>
                    <Col xs={24} sm={24} md={14} lg={14} xl={14} >
                        <div className='checkoutSteps'>
                            <h2 className='headerTitle textCenter'>{stepTitle()}</h2>
                            <AnimatePresence exitBeforeEnter initial={false}>
                                {step(current)}  
                                <div className='navigationSteps'>
                                    {
                                        (current <= (isCashCustomer ? MAXSTEP : MAXSTEP - 1)) && (<motion.div initial='initial' animate="in" exit="out" variants={pageVariants}>
                                        <Button loading={loading} type="text" className='navigationStep' onClick={() => handleGoBack()}><ArrowLeftOutlined />{prevStepTitle()}</Button>
                                        </motion.div>)
                                    }
                                    {
                                        (current < (isCashCustomer ? MAXSTEP : MAXSTEP - 1) && address !== '') && (<motion.div initial='initial' animate="in" exit="out" variants={pageVariants}>
                                        <Button loading={loading} type="text" className='navigationStep' onClick={() => handleNext(current)}>{nextStepTitle()} <ArrowRightOutlined /></Button>
                                        </motion.div>)
                                    }
                                </div>
                            </AnimatePresence>
                        </div>
                    </Col>
                    <Col xs={24} sm={24} md={10} lg={10} xl={10}>
                        <div className='orderSummary'>
                            <h2 className='headerTitle'>ORDER SUMMARY</h2>
                            <OrderSummaryInfo orderBaseTotalExGST = {orderBaseTotalExGST} freightChargeExGST = {freightChargeExGST} adminFeeExGST = {adminFeeExGST} totalGST = {totalGST} totalIncGST = {totalIncGST} />
                            {
                                (current === MAXSTEP && displayCheckoutButton && payment !== PaymentMethodBean.PAYPAL.getId()) ? <Button loading={loading} htmlType='submit' type='primary' block className='marginBottom20px' onClick={handleCheckout}>{(!isCashCustomer) || payment === PaymentMethodBean.DIRECT_DEPOSIT.getId() ? 'Place Order' : (PaymentMethodBean.get(payment) ? PaymentMethodBean.get(payment)?.getDescription() + ' ' : '') + 'Payment'}</Button> : null
                            }
                            { isCashCustomer && current === MAXSTEP && payment === PaymentMethodBean.PAYPAL.getId() && payPalPayment !== '' ?
                                <PaypalButton intent='capture' clientId={paypalClientId} currency='AUD' total={payPalPayment} orderNo={webOrderNo} style={{size: 'responsive', layout: 'horizontal', shape: 'rect', }}
                                    onApprove={(data) => {
                                        // console.log("Hello from onApprove - data=" + JSON.stringify(data) + " actions=" + JSON.stringify(actions));
                                        restClient.updatePayPalOrderPayment(webOrderNo, data.orderID, data.payerID, updatePayPalOrderPaymentCallback);
                                    }}
                                    onCancel={(data) => {
                                        // console.log("Hello from onCancel - data=" + JSON.stringify(data) + " actions=" + JSON.stringify(actions));
                                        setPayPalPayment('');
                                        setPayment('');
                                        setCurrent(3);
                                        alert("Payment cancelled - please select payment method");
                                    }}
                                />                                
                    : null
                    }
                    { isCashCustomer ?
                            <div>
                                <p className='fontSize16px'>We accept</p>
                                
                                <div className='displayInlineBlock'>
                                    <PaypalIcon/>
                                </div>

                                <div className='displayInlineBlock marginLeft20px'>
                                    <MasterCardIcon/>
                                </div>

                                <div className='displayInlineBlock marginLeft20px'>
                                    <VisaIcon/>
                                </div>

                                <div className='displayInlineBlock marginLeft20px'>
                                    <AmexIcon />
                                </div>
                            </div> : null }
                        </div>                        
                    </Col>
                </Row>
            </div>
            <Modal
                title="Payment"
                visible={checkoutPaymentVisible}
                centered
                closable={true}
                footer={null}
                width={600}
                onCancel={() => {
                    setCheckoutPaymentVisible(false);
                    setPayment('');
                    setCurrent(3);
                    alert("Payment cancelled - please select payment method");
                }}
            >
                <iframe key={iframeKey} title='Credit Card Payment' src={iframeSrc} id='paymentIFrame' frameBorder='0' onLoad={() => iFrameChange()}></iframe>
            </Modal>
        </CheckoutLayout>
    );
}

export default CheckoutPage;
